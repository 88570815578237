'use strict';

angular.module('enervexSalesappApp').factory('Icon', function ($resource) {
	return $resource('/api/icons/:id', {
		id: '@_id',
		merge: '@merge'
	},
	{
		update: {
			method: 'PUT'
		}
	});
});
